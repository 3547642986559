import {
    Container,
    Row,
    Col,
} from "reactstrap";
import styled from "styled-components";
import logo from "../../assets/img/brand/logo.png"
const ImageLogo = styled.img`
    width: 100px;
    margin: 0 auto;
    display: flex;
`;

const PublicCardHeader = ({ title, description }) => {

    return (
        <>
            <ImageLogo alt="..." src={logo}/>
            <Container>
                <div className="header-body text-center">
                    <Row className="justify-content-center">
                        <Col>
                            <h1 style={{ color: '#172b4d' }}>{title}</h1>
                            <p className="text-lead" style={{ color: '#172b4d' }}>
                                {description}
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    );
};

export default PublicCardHeader;
