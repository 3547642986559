import { request } from "../helpers/request"
const BASE_URL = '/products'

export const getOne = async (productId) => {
    const { data } = await request.get(`${BASE_URL}/${productId}`);
    return data;
}

export const getCategory = async (categoryId) => {
    const { data } = await request.get(`${BASE_URL}/category/${categoryId}`);
    return data;
}

export const createProduct = (product) => {
    return request.post(`${BASE_URL}`, product)
}

export const updateProduct = (product) => {
    return request.put(`${BASE_URL}/${product.productId}`, product)
}

export const createCategory = (category) => {
    return request.post(`${BASE_URL}/category`, category)
}

export const updateCategory = (categoryId, name) => {
    return request.put(`${BASE_URL}/category/${categoryId}`, {
        name
    })
}

export const deleteCategory = (categoryId) => {
    return request.delete(`${BASE_URL}/category/${categoryId}`);
}

export const addProductToCategory = (productId, categoryId) => {
    return request.put(`${BASE_URL}/${productId}/category/${categoryId}`)
}

export const deleteProductFromCategory = (productId, categoryId) => {
    return request.delete(`${BASE_URL}/${productId}/category/${categoryId}`)
}

export const deleteProductExtra = (extraId) => {
    return request.delete(`${BASE_URL}/extra/${extraId}`)
}

export const deleteProduct = (productId) => {
    return request.delete(`${BASE_URL}/${productId}`)
}

export const addProductLogo = (productId, file) => {
    const formData = new FormData();
    formData.append("logo", file);
    return request.put(`${BASE_URL}/${productId}/logo`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const bulkUploadCsv = (storeId, file) => {
    const formData = new FormData();
    formData.append("csv", file);
    return request.post(`${BASE_URL}/${storeId}/csv`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}