import {
    Row,
    Col,
    Button,
} from "reactstrap";
import { useContext } from 'react';
import { useState } from 'react';
import { AppContext } from '../../AppContext';
import { Button as AntdButton, Card as AntdCard, message, Divider, Modal, Popover, Space, InputNumber, Tag } from 'antd';
import { ExclamationCircleOutlined, CustomerServiceOutlined } from '@ant-design/icons'
import moment from "moment";
import OrderStatus from "components/OrderStatus";
import { updateOrder } from "services/order.service";
import { OrderStatusText } from "helpers/utils";
import { updateOrderEdt } from "services/order.service";
import { useHistory } from "react-router";

export default function OrderCard({ order, onOrderUpdated }) {
    const history = useHistory();
    const [edtMinutes, setEdtMinutes] = useState(15);
    const { loading, setLoading, setLoadingText, chatClient, store } = useContext(AppContext);

    const updateOrderStatus = (orderId, status) => {
        if (!status) return;
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: <div>Are you sure to change the order status to: {OrderStatus[status]}?</div>,
            okText: 'Update',
            cancelText: 'Cancel',
            onOk: async () => {
                try {
                    setLoadingText('Updating Order...');
                    setLoading(true);
                    await updateOrder(orderId, status);
                    setLoading(false);
                    setLoadingText(null);
                    onOrderUpdated();
                } catch (e) {
                    setLoading(false);
                    setLoadingText(null);
                    message.error("Error updating order");
                }
            }
        });
    }

    const content = orderId => (
        <Space>
            <InputNumber min={1} max={60} value={edtMinutes} onChange={setEdtMinutes} />
            <Button
                disabled={loading}
                color="primary"
                onClick={async () => {
                    setLoading(true);
                    await updateOrderEdt(orderId, edtMinutes);
                    await onOrderUpdated();
                    setLoading(false);
                }}
            >
                Update
            </Button>
        </Space>
    );

    const openChannel = async order => {
        try {
            if (store.id && chatClient) {
                try {
                    setLoading(true);
                    const channels = await chatClient.getSubscribedChannels();
                    const channel = channels.items.find(c => c.channelState.uniqueName === 'store_customer_' + order.id);
                    history.push(`/admin/chat?sid=${channel.sid}`);
                    setLoading(false);
                } catch (e) {
                    console.log(e)
                    const channel = await chatClient.createChannel({ uniqueName: 'store_customer_' + order.id, friendlyName: `Order #${order.orderNo} - ${order.user.firstName}` });
                    channel.join();
                    channel.add(order.user.id);
                    console.log('created')
                    setLoading(false);
                    history.push(`/admin/chat?sid=${channel.sid}`);
                }
            }
        } catch (e) {
            console.log(e)
            setLoading(false);
        }
    }

    return (
        <AntdCard
            style={{ backgroundColor: moment(order.dateCreated).fromNow() === 'a few seconds ago' ? '#e8fbe8' : 'white' }}
            headStyle={{ textTransform: 'capitalize' }}
            title={`Order #${order.orderNo}`}
            extra={(order.type !== "IN_STORE" || order.status !== "COMPLETED")  && <AntdButton style={{ alignItems: 'center', display: 'flex' }} icon={<CustomerServiceOutlined />} onClick={() => openChannel(order)} type="link">Chat with customer</AntdButton>}
            bordered={false}>
            <h3><b>Customer: </b>{order.user ? `${order.user.firstName} ${order.user.lastName}` : order.userName}</h3>
            <Row>
                <Col>
                    <h4>Total: {(parseFloat(order.totalEarnedByStore) / 100).toFixed(2)}$</h4>
                </Col>
                <Col>
                    <h4>{OrderStatus[order.status]}</h4>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h4 style={{ color: 'rgb(60, 180, 76)' }}>{order.type.replace("_", ' ')}</h4>
                </Col>
                <Col>
                    <h5>Created {moment(order.dateCreated).fromNow()}</h5>
                </Col>
            </Row>
            <Row>
                <Col>
                    {order.type === "DELIVERY" && <h4>Driver: {order.driver ? `${order.driver.firstName}` : 'Unassigned'}</h4>}
                </Col>
            </Row>
            <Row>
                <Col>
                    {
                        order.dateEstimatedDelivery && (
                            <Popover content={() => content(order.id)} title={`Estimated ready time: ${moment.utc(order.dateEstimatedDelivery).local().format("DD/MM/YY hh:mm:ss A")}`}>
                                <h4 style={{ cursor: 'pointer' }}><span>Order Ready</span> {moment(order.dateEstimatedDelivery).fromNow()}</h4>
                            </Popover>
                        )
                    }
                </Col>
            </Row>
            {order.stripeTransactionId === "CASH" && <Row>
                <Col>
                    <h4><Tag color="green">CASH</Tag></h4>
                </Col>
            </Row>}
            {[OrderStatusText.READY_FOR_DRIVER, OrderStatusText.COMPLETED].indexOf(order.status) === -1 && <Divider />}
            {[OrderStatusText.READY_FOR_DRIVER, OrderStatusText.COMPLETED].indexOf(order.status) === -1 && <h3>Update order status</h3>}
            {order.status === OrderStatusText.PAID && <Button onClick={() => updateOrderStatus(order.id, OrderStatusText.IN_PROGRESS)} color="primary">Start order preparation</Button>}
            {(order.status === OrderStatusText.READY_FOR_PICKUP) && <Button onClick={() => updateOrderStatus(order.id, OrderStatusText.COMPLETED)} color="primary">Order completed</Button>}
            {order.status === OrderStatusText.IN_PROGRESS && (
                order.type === 'DELIVERY' ? <Button onClick={() => updateOrderStatus(order.id, OrderStatusText.READY_FOR_DRIVER)} color="primary">Ready for driver</Button>
                    : <Button onClick={() => updateOrderStatus(order.id, OrderStatusText.READY_FOR_PICKUP)} color="primary">Ready for pickup</Button>
            )}
            <Divider />
            <h4>Items</h4>
            {
                order.cart.cartItems.map(item =>
                    <li style={{ borderBottom: '1px solid lightgray', padding: '0px 10px' }} key={item.id}>
                        <p>{item.product.name} x {item.quantity}</p>
                        <ul>
                            {item.extras.map(extra => <li>{extra.name}</li>)}
                        </ul>
                    </li>
                )
            }
        </AntdCard>
    );
}