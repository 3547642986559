import React, { useEffect, useState, useCallback } from 'react';
import { getStoreInfo, updateFcmToken } from './services/store.service';
import { getTwilioToken, getUserInfo } from './services/user.service';
import { messaging } from "helpers/firebase.helper";
import TwilioChat from "twilio-chat";
import { Loader } from 'components/Loader';
import { message } from 'antd';
import { getLiveOrders } from 'services/order.service';
import notificationSound from './assets/audio/notification.mp3'
import { isLogged } from 'helpers/utils';
const AppContext = React.createContext({});
const FCM_PUBLIC_KEY = 'BL1qheqLsuWxc2T3vjqJqU9cEP2SInqrw_hgthun-wxVZ_N6H45UOPvYryFRN8RhEXB_FhuuDf8txLr_n90vQyI';

const AppContextProvider = ({ children }) => {
	const [user, setUser] = useState({});
	const [store, setStore] = useState({});
	const [liveOrders, setLiveOrders] = useState([]);
	const [fcmToken, setFcmToken] = useState(null);
	const [adminIdentity, setAdminIdentity] = useState(null);
	const [chatClient, setChatClient] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loadingText, setLoadingText] = useState(null);

	const fetchLiveOrders = useCallback(async () => {
		try {
			if (store.id) {
				const orders = await getLiveOrders(store.id);
				setLiveOrders(orders);
			}
		} catch (e) {
			if (e.response)
				message.error(e.response.data.error.message)
			else
				message.error('Something failed')
		}

	}, [store.id])

	useEffect(() => {
		if (store.id) {
			if (fcmToken) {
				updateFcmToken(store.id, fcmToken);
				if (chatClient) chatClient.setPushRegistrationId('fcm', fcmToken);
			}
			fetchLiveOrders();
		}
	}, [store, fcmToken, chatClient, fetchLiveOrders]);

	const fetchStoreInfo = async () => {
		setLoading(true);
		const _store = await getStoreInfo(localStorage.getItem('storeId'));
		const _user = await getUserInfo();
		setStore(_store);
		setUser(_user);
		setLoading(false);
	};

	useEffect(() => {
		if (isLogged()) fetchStoreInfo();
	}, []);

	useEffect(() => {
		const initFirebase = async () => {
			if ("serviceWorker" in navigator) {
				try {
					const registration = await navigator.serviceWorker.register("../firebase-messaging-sw.js");
					navigator.serviceWorker.addEventListener("message", message => {
						// chatClient.handlePushNotification(message).catch(console.log);
						try {
							const data = message.data['firebase-messaging-msg-data'].data;
							if (!data.twi_message_type) {
								(new Audio(notificationSound)).play();
								const title = data.title;
								const options = {
									body: data.body
								};
								registration.showNotification(title, options);
								fetchLiveOrders();
							}
						} catch (e) {
							console.log(e)
						}
					});

					var currentToken = await messaging.getToken({ vapidKey: FCM_PUBLIC_KEY, serviceWorkerRegistration: registration });
					if (currentToken && store.fcmToken !== currentToken) {
						setFcmToken(currentToken);
					} else {
						// No registration token available. Request permission to generate one.
						await messaging.requestPermission()
						currentToken = await messaging.getToken({ vapidKey: FCM_PUBLIC_KEY, serviceWorkerRegistration: registration });
						if (store.fcmToken !== currentToken) setFcmToken(currentToken);
					}
				} catch (err) {
					console.log("Service worker registration failed, error:", err);
				}
			}
		}
		initFirebase();
	}, [store, fetchLiveOrders, chatClient])


	useEffect(() => {
		const init = async () => {
			try {
				const token = await getTwilioToken(store.id);
				const client = new TwilioChat(token.jwt);
				setAdminIdentity(token.adminIdentity);
				client.on("tokenAboutToExpire", async () => {
					// const token = await getTwilioToken(store.id);
					// client.updateToken(token.jwt);
				});

				client.on("tokenExpired", async () => {
					// const token = await getTwilioToken(store.id);
					// client.updateToken(token.jwt);
				});
				setChatClient(client);
			} catch (e) {
				console.log(e)
				setLoading(false);
			}
		}
		if (store.id) init();
	}, [store]);

	return (
		<AppContext.Provider
			value={{
				user,
				setUser,
				store,
				setStore,
				setLoading,
				loading,
				liveOrders,
				adminIdentity,
				setAdminIdentity,
				chatClient,
				setChatClient,
				setLoadingText,
				fetchLiveOrders,
				fetchStoreInfo
			}}
		>
			{loading && <Loader text={loadingText || 'Loading store'} />}
			{children}
		</AppContext.Provider>
	);
};

export { AppContext, AppContextProvider };
