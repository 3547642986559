/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useContext, useEffect, useState } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
	Card,
	CardHeader,
	CardBody,
	NavItem,
	NavLink,
	Nav,
	Container,
	Row,
	Col,
} from "reactstrap";

// core components
import {
	chartOptions,
	parseOptions,
} from "variables/charts.js";
import { Skeleton } from 'antd';
import Header from "components/Headers/Header.js";
import Subscribe from "./Subscribe";
import { AppContext } from "AppContext";
import { getDashboardStats } from "services/order.service";
import { performanceOptions, salesOptions } from "./chartOptions";

const Dashboard = () => {
	const { store, setLoading, setLoadingText } = useContext(AppContext)
	const [performanceData, setPerformanceData] = useState();
	const [salesData, setSalesData] = useState();

	if (window.Chart) {
		parseOptions(Chart, chartOptions());
	}

	useEffect(() => {

		const getStats = async () => {
			try {
				setLoadingText('Loading stats');
				setLoading(true);
				const data = await getDashboardStats(store.id);
				const performance = [];
				const sales = [];
				const MONTHS = [null, "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
				for (const index in MONTHS) {
					if (index === 0) continue;
					const month = data.ordersByMonth.find(item => {
						return parseInt(item.month) === parseInt(index)
					});
					const saleMonth = data.revenueByMonth.find(item => {
						return parseInt(item.month) === parseInt(index)
					});
					performance.push(month ? parseInt(month.total) : 0);
					sales.push(saleMonth ? (parseFloat(saleMonth.total) / 100).toFixed(2) : 0);
				}
				MONTHS.splice(0, 1);
				const chartData = {
					labels: MONTHS,
					datasets: [
						{
							label: "Performance",
							data: performance,
						},
					],
				}
				const salesData = {
					labels: MONTHS,
					datasets: [
						{
							label: "Sales",
							data: sales,
							maxBarThickness: 10,
						},
					],
				}
				setPerformanceData(chartData);
				setSalesData(salesData);
				setLoading(false);
				setLoadingText(null);
			} catch (e) {
				setLoading(false);
				setLoadingText(null);
			}

		}
		if (store.id) getStats();
	}, [store, setLoading, setLoadingText]);


	if (!store.id) return <Skeleton />;

	if (store && store.status === 'INACTIVE') return <Subscribe />

	return (
		<>
			<Header />
			{/* Page content */}
			<Container className="mt--7" fluid>
				<Row>
					<Col className="mb-5 mb-xl-0" xl="8">
						<Card className="bg-gradient-default shadow">
							<CardHeader className="bg-transparent">
								<Row className="align-items-center">
									<div className="col">
										<h6 className="text-uppercase text-light ls-1 mb-1">
											Overview
										</h6>
										<h2 className="text-white mb-0">Sales value</h2>
									</div>
									<div className="col">
										<Nav className="justify-content-end" pills>
											<NavItem>
												<NavLink
													className={classnames("py-2 px-3")}
													href="#month"
												>
													<span className="d-none d-md-block">Month</span>
													<span className="d-md-none">M</span>
												</NavLink>
											</NavItem>
										</Nav>
									</div>
								</Row>
							</CardHeader>
							<CardBody>
								{/* Chart */}
								<div className="chart">
									<Line
										data={salesData}
										options={salesOptions.options}
										getDatasetAtEvent={(e) => console.log(e)}
									/>
								</div>
							</CardBody>
						</Card>
					</Col>
					<Col xl="4">
						<Card className="shadow">
							<CardHeader className="bg-transparent">
								<Row className="align-items-center">
									<div className="col">
										<h6 className="text-uppercase text-muted ls-1 mb-1">
											Performance
										</h6>
										<h2 className="mb-0">Total orders</h2>
									</div>
								</Row>
							</CardHeader>
							<CardBody>
								{/* Chart */}
								<div className="chart">
									<Bar
										data={performanceData}
										options={performanceOptions.options}
									/>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default Dashboard;
