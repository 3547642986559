import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "AppContext";
import { ChatList, SideBar, Input, Button as ChatButton } from 'react-chat-elements'
import grubxIcon from 'assets/img/brand/grubx.png'
import { ChatFeed, Message } from 'react-chat-ui'
import moment from "moment";
import styled from 'styled-components'
import { Button, Card } from "antd";
import { Container } from "reactstrap";
import Header from "components/Headers/Header";

const MessagesContainer = styled.div`
    padding: 0px 20px;
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
`;

const ChatContainer = styled.div`
    display: flex;

    .rce-sbar {
        justify-content: flex-start;
    }
`;

function Chats({ location }) {
    const [channels, setChannels] = useState([]);
    const [openedChannel, setOpenedChannel] = useState({});
    const [messages, setMessages] = useState([]);
    const [adminChannelSid, setAdminChannelSid] = useState(null);
    const [, setCounter] = useState(1)
    const inputRef = useRef();
    const { store, setLoading, adminIdentity, chatClient } = useContext(AppContext)

    useEffect(() => {
        const getSubscribedChannels = async () => {
            if (chatClient && store) {
                // setLoading(true);
                const channels = await chatClient.getSubscribedChannels();
                const channelsProcessed = channels.items.map(channel => ({
                    avatar: channel.channelState.uniqueName === store.id + adminIdentity ? grubxIcon : store.logoUrl,
                    title: channel.channelState.friendlyName,
                    subtitle: 'Last message: ' + moment(channel.channelState.lastMessage ? channel.channelState.lastMessage.dateCreated : new Date()).fromNow(),
                    date: channel.channelState.dateCreated,
                    unread: channel.channelState.lastMessage && channel.channelState.lastConsumedMessageIndex !== channel.channelState.lastMessage.index,
                    sid: channel.sid,
                    statusColor: openedChannel.sid === channel.sid ? '#1171ef' : '',
                    statusColorType: 'encircle'
                }));
                const adminChannel = channels.items.find(channel => channel.channelState.uniqueName === store.id + adminIdentity);
                if (adminChannel) {
                    setChannels(channelsProcessed || []);
                    setAdminChannelSid(adminChannel.sid);
                } else {
                    setChannels([{
                        avatar: grubxIcon,
                        title: `${store.name} - Support GrubX`,
                        subtitle: 'Let us know if you need anything!',
                        date: new Date(),
                        unread: 0,
                        sid: 'channel.sid'
                    }].concat(channelsProcessed));
                }
                // setLoading(false);
            }
        }

        getSubscribedChannels();

    }, [chatClient, store, openedChannel]);

    useEffect(() => {
        if (messages.length > 0) {
            const index = messages[messages.length - 1].index;
            if (index) {
                openedChannel.updateLastConsumedMessageIndex(index);
            }
        }
    }, [messages]);

    useEffect(() => {
        const chatSid = new URLSearchParams(location.search).get("sid");
        if (channels.length && chatSid && !openedChannel.sid) {
            openChannel({ sid: chatSid });
        }
    }, [channels])

    const addMessage = ({ author, body, userName }) => {
        const message = new Message({
            id: author === store.id ? 0 : author,
            message: body,
            senderName: userName
        });
        setMessages(prev => {
            prev.push(message)
            return prev;
        });
        setCounter(prev => prev + 1)
    }


    const openChannel = async _channel => {
        try {
            if (store.id && chatClient) {
                setLoading(true);
                let channel;
                if (_channel.sid === 'channel.sid') {
                    channel = await chatClient.createChannel({ uniqueName: store.id + adminIdentity, friendlyName: `${store.name} - GrubX Support` });
                    channel.join();
                    channel.add(adminIdentity);
                } else {
                    channel = await chatClient.getChannelBySid(_channel.sid);
                }
                setOpenedChannel(channel);
                const messages = await channel.getMessages();
                setMessages(messages.items.map(m => ({
                    senderName: m.state.attributes.userName || 'Customer',
                    id: m.state.author === store.id ? 0 : m.state.author,
                    message: m.state.body,
                    index: m.state.index,
                })));
                channel.removeAllListeners();
                channel.on('messageAdded', ({ author, channel, body, attributes }) => {
                    console.log(channel)
                    addMessage({ author, body, userName: attributes.userName || 'Customer' })
                });

                setLoading(false);
            }
        } catch (e) {
            console.log(e)
            setLoading(false);
        }
    }

    const sendMessage = (e) => {
        e.preventDefault();
        openedChannel.sendMessage(inputRef.current.input.value, {
            userId: store.id,
            userName: store.name,
            storeId: store.id
        })
        inputRef.current.input.value = "";
    }

    return (
        <>
            <Header />
            <Container className="mt--7" fluid>
                <div>
                    {adminChannelSid && <Button type="dashed" onClick={() => openChannel({ sid: adminChannelSid })}>Chat with GrubX Support</Button>}
                </div>
                <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
                    <ChatContainer>
                        <SideBar
                            type="light"
                            center={
                                <div>
                                    <ChatList
                                        className='chat-list'
                                        dataSource={channels}
                                        onClick={openChannel}

                                    />
                                </div>
                            } />
                        <MessagesContainer>
                            <ChatFeed
                                messages={messages} // Array: list of message objects
                                hasInputField={false} // Boolean: use our input, or use your own
                                showSenderName // show the name of the user who sent the message
                                bubblesCentered={false} //Boolean should the bubbles be centered in the feed?
                                // JSON: Custom bubble styles
                                bubbleStyles={
                                    {
                                        text: {
                                            fontSize: 18
                                        },
                                        chatbubble: {
                                            borderRadius: 70,
                                            padding: 10
                                        }
                                    }
                                }
                            />
                            <form onSubmit={sendMessage}>
                                <Input
                                    placeholder="Type here..."
                                    multiline={false}
                                    ref={inputRef}
                                    rightButtons={
                                        <ChatButton
                                            color='white'
                                            backgroundColor='black'
                                            text='Send' />
                                    }
                                />
                            </form>
                        </MessagesContainer>
                    </ChatContainer>
                </Card>

            </Container>
        </>
    );
}

export default Chats;
