import styled from 'styled-components';
import { Drawer } from 'antd';

export const Logo = styled.img`
	width: 200px;
	margin: 34px auto;
	display: block;
`;

export const ProductExtrasContainer = styled.div`
	
	.form-container-extras {
		display:flex;
		flex-direction: row;
		justify-content: space-evenly;
	}
`;

export const LoginContainer = styled.div`
	height: 95vh;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const ButtonsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding-top: 30px;
	padding-left: 10px;
	button {
		margin-left: 10px;
		align-items: center;
		display: flex;
	}
	select {
		width: 150px !important;
	}

	@media only screen and (max-width: 600px) {
		button {
			margin-top: 10px;
			margin-right: 10px;
		}
		.btn + .btn {
			margin-left: 0px;
		}
	}
`;

export const ProductsTableContainer = styled.div`
	margin-left: 10px;
	margin-right: 10px;
`;

export const CustomDrawer = styled(Drawer)`
	.ant-drawer-content-wrapper {
		width: 520px !important;
	}    

	@media only screen and (max-width: 600px) {
		.ant-drawer-content-wrapper {
			width: 100% !important;
		}
	}
`;