import { Button, Col, Input, Row, Table } from "reactstrap";
import { Divider, Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons'
import moment from 'moment';
import { ButtonsContainer } from "./styled";
import { useEffect, useState } from "react";
import { Loader } from "components/Loader";
import { updateOrder } from "services/order.service";
import OrderStatus from "components/OrderStatus";
import RefundedStatus from "components/RefundedStatus";

const OrderInformation = ({ order = {}, onOrderUpdated }) => {
	const [currentOrder, setCurrentOrder] = useState(order);
	const { user = {}, driver = {}, cart = {} } = order;
	const [orderStatus, setOrderStatus] = useState('');
	const [loading, setLoading] = useState(false);

	const updateOrderStatus = status => {
		if (!status) return;
		Modal.confirm({
			title: 'Confirm',
			icon: <ExclamationCircleOutlined />,
			content: <div>Are you sure to change the order status to: {OrderStatus[status]}?</div>,
			okText: 'Update',
			cancelText: 'Cancel',
			onOk: async () => {
				try {
					const { data: updatedOrder } = await updateOrder(currentOrder.id, status);
					setCurrentOrder(updatedOrder);
					onOrderUpdated(updatedOrder);
					setLoading(false);
				} catch (e) {
					setLoading(false);
					message.error(e.response ? e.response.data.error.message :  "Error updating order");
				}
			}
		});
	}

	useEffect(() => {
		setCurrentOrder(order)
	}, [order])

	return (
		<div>
			{loading && <Loader text={'Updating order status...'} />}
			<Row>
				<Col xs={6} md={6}>
					<h3>Created</h3>
					<p>{moment.utc(currentOrder.dateCreated).local().format("DD/MM/YY")}</p>
				</Col>
				<Col xs={6} md={6}>
					<h3>Customer</h3>
					<p>{order.user ? `${user.firstName} ${user.lastName}` : order.userName}</p>
				</Col>
			</Row>
			<Row>
				<Col xs={6} md={6}>
					<h3>Total</h3>
					<p>{(parseFloat(currentOrder.totalEarnedByStore) / 100).toFixed(2)}$</p>
				</Col>
				<Col xs={6} md={6}>
					<h3>Status</h3>
					<p>{OrderStatus[currentOrder.status]}</p>
				</Col>
			</Row>
			{currentOrder.refundedStatus && (
				<Row>
					<Col xs={6} md={6}>
						<h3>Total refunded</h3>
						<p>{(parseFloat(currentOrder.totalRefunded) / 100).toFixed(2)}$</p>
					</Col>
					<Col xs={6} md={6}>
						<h3>Refunded</h3>
						<p>{RefundedStatus[currentOrder.refundedStatus]}</p>
					</Col>
				</Row>
			)
			}
			<Row>
				<Col xs={6} md={6}>
					<h3>Driver</h3>
					<p>{driver ? `${driver.firstName} ${driver.lastName}` : 'Unassigned'}</p>
				</Col>
				<Col xs={6} md={6}>

				</Col>
			</Row>
			{currentOrder.status !== 'COMPLETED' && currentOrder.status !== 'VOIDED' && <Divider />}
			{currentOrder.status !== 'COMPLETED' && currentOrder.status !== 'VOIDED' && <h3>Update order status</h3>}
			{currentOrder.status === 'PAID' && <Button onClick={() => updateOrderStatus('IN_PROGRESS')} color="primary">Start order preparation</Button>}
			{(currentOrder.status === 'READY_FOR_PICKUP' || currentOrder.status === 'READY_FOR_DRIVER') && <Button onClick={() => updateOrderStatus('COMPLETED')} color="primary">Order completed</Button>}

			{['IN_PROGRESS'].indexOf(currentOrder.status) !== -1 && <ButtonsContainer>
				<Input type="select" style={{ width: 120 }} value={orderStatus} onChange={e => setOrderStatus(e.target.value)}>
					<option value=''>-- Pick one --</option>
					{
						currentOrder.type === 'DELIVERY' ? <option value="READY_FOR_DRIVER">Ready for driver</option> : <option value="READY_FOR_PICKUP">Ready for pickup</option>
					}
					<option value="REFUNDED">Refunded</option>
					<option value="PARTIALLY">Partially refunded</option>
					<option value="VOIDED">Voided</option>
				</Input>
				<Button color="primary" onClick={() => updateOrderStatus(orderStatus)}>Update</Button>
			</ButtonsContainer>}
			<Divider />
			<h3 style={{ marginTop: 10 }}>Cart items</h3>
			<Table>
				<thead>
					<tr>
						<th>Product</th>
						<th>Quantity</th>
						<th>Total</th>
					</tr>
				</thead>
				<tbody>
					{
						(cart.cartItems || []).map(cartItem =>
							<tr key={cartItem.id}>
								<td>{cartItem.product.name}</td>
								<td>{cartItem.quantity}</td>
								<td>{(parseFloat(cartItem.total) / 100).toFixed(2)}$</td>
							</tr>
						)
					}
				</tbody>
			</Table>
		</div>
	)
}
export default OrderInformation;