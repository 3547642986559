/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import { AppContext } from "AppContext";
import { Loader } from "components/Loader";
import { saveStoreId } from "helpers/utils";
import { login } from "helpers/utils";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { getStoreInfo } from "services/store.service";
import { getUserInfo } from "services/user.service";
import { loginStore } from "services/user.service";
import { message } from 'antd';
import PublicCardHeader from "components/Headers/PublicCardHeader";

const Login = () => {
  const { setUser, setStore } = useContext(AppContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(null);
  const history = useHistory();

  const onFinish = async () => {
		try {
			setLoading('Authenticating...');
			const { data } = await loginStore({email, password});
			saveStoreId(data.store);
			login(data.token);
			setUser(await getUserInfo());
			setStore(await getStoreInfo(data.store));
			setLoading(null);
			history.push('/admin/dashboard');
		} catch(e) {
			setLoading(null);
			message.error('Email or password wrong');
		}
	};

  return (
    <>
      {loading && <Loader text={loading} />}
      <Col lg="5" md="7">
        <Card className="bg-secondary">
          
          <CardBody className="px-lg-5 py-lg-5">
            <PublicCardHeader title="Store management portal" description="Welcome, here you can full manage your store" />
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    onChange={e => setPassword(e.target.value)}
                    value={password}
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button onClick={onFinish} className="my-4" color="primary" type="button">
                  Sign in
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <a className="text-light" href="/reset-password"> <small>Forgot password?</small></a>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Login;
