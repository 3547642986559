import {
    Card, CardText, CardBody,
    CardTitle, CardSubtitle, Button,
    Row,
    Container,
    Col,
} from "reactstrap";
import { Tag, message } from 'antd'
import { useContext, useState } from 'react';
import { AppContext } from '../../AppContext';
import { Loader } from '../../components/Loader';
import Header from 'components/Headers/Header';
import { generateStripeSession } from "services/store.service";

export default function Subscribe() {
    const [loading, setLoading] = useState(null);
    const { store } = useContext(AppContext);

    const onSubscribe = async plan => {
        try {
            setLoading(true);
            const sessionUrl = await generateStripeSession(store.id, plan);
            setLoading(false);
            window.location.href = sessionUrl;
        } catch (e) {
            setLoading(false);
            message.error(e.response.data.error.message);
        }
    }

    return (
        <>
            {loading && <Loader text={'Please wait...'} />}
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row>
                    <Col>
                        <Card className="shadow border-0">
                            <CardBody>
                                <CardTitle tag="h1">Your store is inactive, please choose a plan and get:</CardTitle>
                                    <ul>
                                        <li>Full control with admin dashboard</li>
                                        <li>Support for takeaway, collection & delivery</li>
                                        <li>Local deliveries without your own fleet</li>
                                        <li>MasterCard, VISA AMEX & Discover payments supported</li>
                                        <li>Full access to customer data and insights</li>
                                        <li>Money paid directly to your bank account</li>
                                        <li>0% commission on all orders</li>
                                    </ul>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <br />
                <br />
                <Row>
                    <Col>
                        <Card className="shadow border-0">
                            <CardBody>
                                <CardTitle tag="h2">{store.homeStore ? 'Home' : ''} Starter plan</CardTitle>
                                {!store.homeStore && <CardSubtitle tag="h4" className="mb-2 text-muted">119$ /month</CardSubtitle>}
                                <CardText><b>Service & Onboarding Fee: 119$</b></CardText>
                                <Button color="primary" onClick={() => onSubscribe('STARTER')}>Subscribe</Button>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="shadow border-0">
                            <CardBody>
                                <CardTitle tag="h2">{store.homeStore ? 'Home' : ''} Pro plan</CardTitle>
                                {!store.homeStore && <CardSubtitle tag="h4" className="mb-2 text-muted">1119$ /year <Tag color="green">Save 22%</Tag></CardSubtitle>}
                                <CardText><b>Service & Onboarding Fee: 499$</b></CardText>
                                <Button color="primary" onClick={() => onSubscribe('PRO')}>Subscribe</Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}