import firebase from "firebase/app";
import "firebase/messaging";
const initializedFirebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyCMtb0DcRGgc3PD_e0ZzPZn2kmO15ZoyFs",
    authDomain: "grubx-a77c9.firebaseapp.com",
    databaseURL: "https://grubx-a77c9.firebaseio.com",
    projectId: "grubx-a77c9",
    storageBucket: "grubx-a77c9.appspot.com",
    messagingSenderId: "804301401897",
    appId: "1:804301401897:web:03bfafb010cf4a4b91d138",
    measurementId: "G-4B7XKZ3NJH"
});
const messaging = initializedFirebaseApp.messaging();
export { messaging };