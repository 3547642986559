import {
	Card,
	Row,
	Col,
	Container
} from "reactstrap";
import { useState, useEffect, useContext, useCallback } from 'react';
import { AppContext } from '../../AppContext';
import { Loader } from '../../components/Loader';
import Header from 'components/Headers/Header';
import { Tabs, Empty, Select } from 'antd';
import OrderCard from "./OrderCard";
import CancelOrderModal from "components/CancelOrderModal";
import { FiltersContainer } from "./styled";
const { TabPane } = Tabs;
const { Option } = Select;

export default function LiveOrders() {
	const [loading, setLoading] = useState(null);
	const [orderToBeVoided, setOrderToBeVoided] = useState({});
	const [orderType, setOrderType] = useState("ALL");
	const [location, setLocation] = useState("ALL");
	const [inProgressOrders, setInProgressOrders] = useState([]);
	const [readyOrders, setReadyOrders] = useState([]);
	const [completedOrders, setCompletedOrders] = useState([]);
	const { store, liveOrders, fetchLiveOrders } = useContext(AppContext);

	const updateLiveOrders = useCallback(async () => {
		setLoading('Fetching orders');
		await fetchLiveOrders();
		setLoading(null);
	}, [fetchLiveOrders]);

	useEffect(() => {
		if (store.id) updateLiveOrders();
	}, [store, updateLiveOrders]);


	useEffect(() => {
		const filterCallback = order => {
			let typeFilter = orderType !== "ALL" ? order.type === orderType : true;
			let locationFilter = location !== "ALL" ? order.location.id === location : true;
			return typeFilter && locationFilter;
		}
		setInProgressOrders((liveOrders.inProgressOrder || []).filter(filterCallback));
		setReadyOrders((liveOrders.readyOrders || []).filter(filterCallback));
		setCompletedOrders((liveOrders.completedOrders || []).filter(filterCallback));
	}, [liveOrders, orderType, location]);

	const onCancelOrder = order => {
		setOrderToBeVoided(order);
	}

	const onCancelModalClosed = e => {
		fetchLiveOrders();
		setOrderToBeVoided({})
	}

	return (
		<>
			{loading && <Loader text={loading} />}
			<CancelOrderModal isVisible={orderToBeVoided.id !== undefined} order={orderToBeVoided} onClosed={onCancelModalClosed} />
			<Header />
			{/* Page content */}
			<Container className="mt--7" fluid>
				<Row>
					<div className="col">
						<Card style={{ padding: 20, backgroundColor: '#eeeeee' }} className="shadow border-0">
							<FiltersContainer>
								<Select defaultValue="ALL" onChange={setOrderType}>
									<Option value="ALL">All orders</Option>
									<Option value="DELIVERY">Delivery</Option>
									<Option value="PICKUP">Pick up</Option>
									<Option value="IN_STORE">In store</Option>
								</Select>
								<Select defaultValue="ALL" onChange={setLocation}>
									<Option value="ALL">All locations</Option>
									{
										(store.locations || []).map(location => <Option key={location.id} value={location.id}>{location.name}</Option>)
									}
								</Select>
							</FiltersContainer>
							<Tabs defaultActiveKey="1" onChange={console.log}>
								<TabPane tab="Live Orders" key="1">
									<Row gutter={16}>
										{!inProgressOrders.length && <Empty style={{ margin: '0 auto' }} />}
										{
											inProgressOrders.map(order => {
												return (
													<Col style={{ marginTop: 20 }} md={3} sm={12} key={order.id}>
														<OrderCard onCancelOrder={onCancelOrder} order={order} onOrderUpdated={fetchLiveOrders} />
													</Col>
												)
											})
										}
									</Row>
								</TabPane>
								<TabPane tab="Ready for Pickup/Delivery" key="2">
									<Row gutter={16}>
										{!readyOrders.length && <Empty style={{ margin: '0 auto' }} />}
										{
											readyOrders.map(order => {
												return (
													<Col style={{ marginTop: 20 }} md={3} sm={12} key={order.id}>
														<OrderCard onCancelOrder={onCancelOrder} order={order} onOrderUpdated={fetchLiveOrders} />
													</Col>
												)
											})
										}
									</Row>
								</TabPane>
								<TabPane tab="Completed Orders" key="3">
									<Row gutter={16}>
										{!completedOrders.length && <Empty style={{ margin: '0 auto' }} />}
										{
											completedOrders.map(order => {
												return (
													<Col style={{ marginTop: 20 }} md={3} sm={12} key={order.id}>
														<OrderCard order={order} onOrderUpdated={fetchLiveOrders} />
													</Col>
												)
											})
										}
									</Row>
								</TabPane>
							</Tabs>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
}