import { request } from "../helpers/request"
const BASE_URL = '/stores'
export const getStoreInfo = async (storeId) => {
    const { data } = await request.get(`${BASE_URL}/${storeId}`);
    return data;
}

export const getProvinces = async () => {
    const { data } = await request.get(`/provinces`);
    return data;
}

export const generateStripeSession = async (storeId, plan) => {
    const { data } = await request.get(`${BASE_URL}/setup-card/${storeId}?grubxPlan=${plan}`);
    return data;
}

export const generateCustomerPortalSession = async (storeId) => {
    const { data } = await request.get(`${BASE_URL}/billing-information/${storeId}`);
    return data;
}

export const generateAccountPortalSession = async (storeId, type) => {
    const { data } = await request.get(`${BASE_URL}/account/${storeId}?type=${type}`);
    return data;
}

export const getStripeAccountInfo = async (storeId) => {
    const { data } = await request.get(`${BASE_URL}/account-information/${storeId}`);
    return data;
}

export const toggleStoreStatus = async (storeId) => {
    const { data } = await request.put(`${BASE_URL}/switch/${storeId}`);
    return data;
}

export const updateFcmToken = async (storeId, fcmToken) => {
    const { data } = await request.put(`${BASE_URL}/fcm-token/${storeId}`, {
        fcmToken
    });
    return data;
}

export const activateStore = async (storeId, sessionId) => {
    const { data } = await request.put(`${BASE_URL}/${storeId}/activate`, { sessionId });
    return data;
}

export const updateStore = async (storeId, data) => {
    return request.put(`${BASE_URL}/${storeId}`, data);
}

export const addNewLocation = async (storeId, locationData) => {
    return request.post(`${BASE_URL}/${storeId}/location`, locationData);
}

export const deleteStoreLocation = async (storeId, locationId) => {
    return request.delete(`${BASE_URL}/${storeId}/location/${locationId}`);
}

export const addStoreLogo = (storeId, file) => {
    const formData = new FormData();
    formData.append("logo", file);
    return request.put(`${BASE_URL}/${storeId}/logo`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}