/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard/index.js";
import Profile from "views/Profile/index.js";
import Products from "views/Products/index.js";
import Orders from "views/Orders/index.js";
import LiveOrders from "views/LiveOrders/index.js";
import SupportChat from "views/SupportChat/index.js";
import StripeResult from "views/StripeResult/index.js";
import Login from "views/Login.js";
import ForgotPassword from "views/ForgotPassword/index.js";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/live-orders",
    name: "Live Orders",
    icon: "ni ni-delivery-fast",
    component: LiveOrders,
    layout: "/admin",
  },
  {
    path: "/orders",
    name: "Orders History",
    icon: "ni ni-bag-17",
    component: Orders,
    layout: "/admin",
  },
  {
    path: "/products",
    name: "Products",
    icon: "ni ni-box-2",
    component: Products,
    layout: "/admin",
  },{
    path: "/chat",
    name: "Support Chat",
    icon: "ni ni-support-16",
    component: SupportChat,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    noShow: true,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    noShow: true,
    component: Login,
    layout: "/",
  },
  {
    path: "/reset-password",
    name: "ForgotPassword",
    icon: "ni ni-key-25 text-info",
    noShow: true,
    component: ForgotPassword,
    layout: "/",
  },
  {
    path: "/result",
    name: "StripeResult",
    icon: "ni ni-key-25 text-info",
    noShow: true,
    component: StripeResult,
    layout: "/admin",
  },
];
export default routes;
