import { Table } from "reactstrap";
import { OrdersTableContainer, OrderTableTr } from './styled';
import moment from 'moment';
import OrderStatus from "components/OrderStatus";
import RefundedStatus from "components/RefundedStatus";

const OrdersTable = ({ orders = [], onOrderOpened }) => {
	return (
		<OrdersTableContainer>
			<Table className="align-items-center table-flush" responsive>
				<thead className="thead-light">
					<tr>
						<th scope="col">Order Number</th>
						<th scope="col">Created</th>
						<th scope="col">Total</th>
						<th scope="col">Ordered By</th>
						<th scope="col">Status</th>
						<th scope="col">Type</th>
					</tr>
				</thead>
				<tbody>
					{orders.map(order => {
						return (
							<OrderTableTr key={order.id} onClick={() => onOrderOpened(order)}>
								<td>{order.orderNo}</td>
								<td>{moment(order.dateCreated).fromNow()}</td>
								<td>{(parseFloat(order.totalEarnedByStore) / 100).toFixed(2)}$</td>
								<td>{order.user ? `${order.user.firstName} ${order.user.lastName}` : order.userName}</td>
								<td style={{ textTransform: 'uppercase' }}>{RefundedStatus[order.refundedStatus]} {OrderStatus[order.status]}</td>
								<td style={{ textTransform: 'uppercase' }}>{order.type.replace('_', ' ')}</td>
							</OrderTableTr>
						)
					})}
				</tbody>
			</Table>
		</OrdersTableContainer>
	)
}

export default OrdersTable;