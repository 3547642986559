import styled from 'styled-components';

export const ButtonsContainer = styled.div`
    display: flex;
    button {
        margin-left: 10px;
        align-items: center;
        display: flex;
    }
    select {
        width: 150px !important;
    }

    .ant-picker-range {
        height: 3em;
        border-radius: 5px;
    }
`;

export const FiltersContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 230px;
`;