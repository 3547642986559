import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    FormText,
    Row,
    Col,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import { useContext, useState, useEffect } from "react";
import { AppContext } from "AppContext";
import { generateCustomerPortalSession } from "services/store.service";
import { Loader } from "components/Loader";
import { ShopOutlined, UserOutlined, ExclamationCircleOutlined, PlusOutlined, LoadingOutlined, QrcodeOutlined } from '@ant-design/icons'
import { updateStore } from "services/store.service";
import { addStoreLogo } from "services/store.service";
import { updateUser } from "services/user.service";
import { message, List, Checkbox, Modal } from "antd";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { LocationDataItem, PlaceOption, PlaceOptionContainer, PlacesLoading } from "./styled";
import { addNewLocation } from "services/store.service";
import { deleteStoreLocation } from "services/store.service";
import { generateAccountPortalSession } from "services/store.service";
import { getStripeAccountInfo } from "services/store.service";
import { getProvinces } from "services/store.service";
import { ORDER_BASE_URL } from "helpers/utils";
const QRCode = require('qrcode.react');

const Profile = () => {
    const { store, user, setUser, fetchStoreInfo } = useContext(AppContext);
    const [storeData, setStoreData] = useState({});
    const [address, setAddress] = useState();
    const [locationName, setLocationName] = useState();
    const [accountInfo, setAccountInfo] = useState();
    const [locationPhone, setLocationPhone] = useState();
    const [userData, setUserData] = useState({});
    const [locationData, setLocationData] = useState({});
    const [provinces, setProvinces] = useState([]);
    const [locationProvince, setLocationProvince] = useState(null);
    const [storeLogo, setStoreLogo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isQRCodeModalVisible, setQRCodeModalVisible] = useState(false);

    const handleOk = () => {
        downloadQR();
        setQRCodeModalVisible(false);
    };

    const handleCancel = () => {
        setQRCodeModalVisible(false);
    };

    const downloadQR = () => {
        const canvas = document.getElementById("store-qr-code-container");
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `${storeData.slug}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };
    const checkBillingInformation = async () => {
        setLoading(true);
        const sessionUrl = await generateCustomerPortalSession(store.id);
        setLoading(false);
        window.location.href = sessionUrl;
    }

    const handleChange = address => {
        setLocationData({})
        setAddress(address)
    };


    const addAccountInfo = async update => {
        try {
            setLoading(true);
            const url = await generateAccountPortalSession(store.id, update);
            setLoading(false);
            window.location.href = url
        } catch (e) {
            setLoading(false);
        }

    }

    const deleteLocation = locationId => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: <div>Are you sure to delete this location?</div>,
            okText: 'Delete',
            cancelText: 'Cancel',
            onOk: async () => {
                try {
                    setLoading(true);
                    await deleteStoreLocation(store.id, locationId)
                    setLoading(false);
                    message.success("Location deleted successfuly");
                    fetchStoreInfo();
                } catch (e) {
                    setLoading(false);
                    message.error(e.response.data.error.message || "Error deleting location");
                }
            }
        });
    }

    const handleSelect = async address => {
        try {
            setAddress(address)
            const results = await geocodeByAddress(address)
            const country = results[0].address_components.find(ac => ac.types.indexOf('country') !== -1);
            const city = results[0].address_components.find(ac => ac.types.indexOf('locality') !== -1);
            const province = results[0].address_components.find(ac => ac.types.indexOf("administrative_area_level_1") !== -1);
            const latLng = await getLatLng(results[0]);
            setLocationData({
                country: country.long_name,
                countryCode: country.short_name,
                province: province.long_name,
                city: city.long_name,
                lat: latLng.lat,
                lng: latLng.lng,
            })
        } catch (e) {
        }
    };

    const addLocation = async () => {
        try {
            if (!locationProvince) return;
            setLoading(true);
            await addNewLocation(store.id, {
                ...locationData,
                latitude: locationData.lat,
                longitude: locationData.lng,
                country: locationData.countryCode,
                province: locationProvince,
                address,
                name: locationName,
                phone: locationPhone
            });
            setLoading(false);
            message.success("Location added successfuly");
            clearLocationForm();
            fetchStoreInfo();
        } catch (e) {
            setLoading(false);
            message.error("Error adding location");
        }

    }

    const clearLocationForm = () => {
        setLocationPhone('');
        setAddress('');
        setLocationName('');
        setLocationData({})
    }

    useEffect(() => {
        const getStripeAccountInformation = async () => {
            try {
                if (store.id) {
                    setLoading(true);
                    setAccountInfo(await getStripeAccountInfo(store.id));
                    setProvinces(await getProvinces())
                    setLoading(false);
                }
            } catch (e) {
                setLoading(false);
            }
        }
        setStoreData({
            name: store.name,
            slug: store.slug,
            orderMinimum: (parseFloat(store.orderMinimum) / 100).toFixed(2),
            pickupAvailable: store.pickupAvailable,
            deliveryAvailable: store.deliveryAvailable
        });
        getStripeAccountInformation();
    }, [store])

    useEffect(() => {
        setUserData({
            firstName: user.firstName,
            lastName: user.lastName,
            phone: user.phone
        })
    }, [user])

    const updateStoreInfo = async () => {
        setLoading(true);
        await updateStore(store.id, {
            ...storeData,
            orderMinimum: parseInt(parseFloat(storeData.orderMinimum).toFixed(2) * 100)
        });
        if (storeLogo) {
            await addStoreLogo(store.id, storeLogo)
        }
        setLoading(false);
        window.location.reload();
    }

    const updateUserInfo = async () => {
        try {
            setLoading(true);
            const { data } = await updateUser(userData);
            setLoading(false);
            setUser(data);
            message.success("User information was updated successfuly");
        } catch (e) {
            message.error("There was an error updating the information, please try again later")
        }
    }

    const onUpdateStoreData = (type, value) => {
        setStoreData(prev => ({
            ...prev,
            [type]: value
        }))
    }

    const onSetUserData = (type, value) => {
        setUserData(prev => ({
            ...prev,
            [type]: value
        }))
    }

    const onUploadLogo = (e) => {
        setStoreLogo(e.target.files[0])
    }

    return (
        <>
            {loading && <Loader text="Please wait..." />}
            <UserHeader user={user} />
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1" xl="8">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">My account</h3>
                                    </Col>

                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Modal visible={isQRCodeModalVisible} okText="Download QR" onOk={handleOk} onCancel={handleCancel}>
                                    <div style={{display: "flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                                        <QRCode
                                            id="store-qr-code-container"
                                            value={`${ORDER_BASE_URL}/store/${storeData.slug}`}
                                            // imageSettings={{
                                            // 	src: grubxLogo,
                                            // 	width: 150,
                                            // 	height: 50,
                                            // 	backgroundColor: 'white'
                                            // }}
                                            size={290}
                                            level={"H"}
                                            includeMargin={true}
                                        />

                                    </div>
                                </Modal>
                                <Form >
                                    <h6 className="heading-small text-muted mb-4">
                                        Store information
                                    </h6>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-username"
                                                    >
                                                        Store name
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        value={storeData.name}
                                                        onChange={e => onUpdateStoreData('name', e.target.value)}
                                                        placeholder="Store name"
                                                        type="text"
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Checkbox checked={storeData.pickupAvailable} onChange={e => onUpdateStoreData('pickupAvailable', e.target.checked)}>Pick up available</Checkbox>
                                                    <Checkbox checked={storeData.deliveryAvailable} onChange={e => onUpdateStoreData('deliveryAvailable', e.target.checked)}>Delivery available</Checkbox>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-username"
                                                    >
                                                        Order minimum
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        value={storeData.orderMinimum}
                                                        step="0.01"
                                                        min="0"
                                                        lang="en"
                                                        onChange={e => onUpdateStoreData('orderMinimum', e.target.value)}
                                                        placeholder="Order minimum"
                                                        type="number"
                                                    />
                                                    <br />
                                                    <FormGroup>

                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="exampleFile"
                                                        >
                                                            Store Logo
                                                        </label>
                                                        <Col sm={10}>
                                                            <Input onChange={onUploadLogo} type="file" name="file" id="exampleFile" />
                                                            <FormText color="muted">
                                                                Please provide the store logo
                                                            </FormText>
                                                        </Col>
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <label className="form-control-label" htmlFor="input-username">
                                                        Store URL: <a target="_blank" rel="noreferrer" href={`${ORDER_BASE_URL}/store/${storeData.slug}`}>{`${ORDER_BASE_URL}/store/${storeData.slug}`}</a>
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        value={storeData.slug}
                                                        lang="en"
                                                        onChange={e => onUpdateStoreData('slug', e.target.value)}
                                                        placeholder="Slug"
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Button onClick={() => setQRCodeModalVisible(true)} style={{ display: "flex", alignItems: 'center' }} color="primary"><QrcodeOutlined />&nbsp;Show QR Code</Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Button onClick={updateStoreInfo} style={{ display: "flex", alignItems: 'center' }} color="primary"><ShopOutlined />&nbsp;Update store</Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="6">
                                            </Col>

                                        </Row>
                                    </div>
                                    <hr className="my-4" />
                                    <h6 className="heading-small text-muted mb-4">
                                        User information
                                    </h6>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-phone"
                                                    >
                                                        Phone number
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        value={userData.phone}
                                                        onChange={e => onSetUserData('phone', e.target.value)}
                                                        id="input-phone"
                                                        placeholder="Phone number"
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-email"
                                                    >
                                                        Email address
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-email"
                                                        disabled={true}
                                                        defaultValue={user.email}
                                                        placeholder="jesse@example.com"
                                                        type="email"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-first-name"
                                                    >
                                                        First name
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-first-name"
                                                        placeholder="First name"
                                                        value={userData.firstName}
                                                        onChange={e => onSetUserData('firstName', e.target.value)}
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-last-name"
                                                    >
                                                        Last name
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-last-name"
                                                        placeholder="Last name"
                                                        value={userData.lastName}
                                                        onChange={e => onSetUserData('lastName', e.target.value)}
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Button onClick={updateUserInfo} style={{ display: "flex", alignItems: 'center' }} color="primary"><UserOutlined />&nbsp;Update information</Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                    <hr className="my-4" />
                                    {/* Address */}
                                    <h6 className="heading-small text-muted mb-4">
                                        Store Locations
                                    </h6>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col>
                                                <List
                                                    itemLayout="horizontal"
                                                    dataSource={store.locations || []}
                                                    renderItem={location => (
                                                        <List.Item
                                                            actions={[<Button color="transparent" onClick={() => deleteLocation(location.id)} key="list-loadmore-more">Delete</Button>]}
                                                        >
                                                            <List.Item.Meta
                                                                title={location.name}
                                                                description={`${location.address}`}
                                                            />
                                                        </List.Item>
                                                    )}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                    <hr className="my-4" />
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col md="6">
                                                <PlacesAutocomplete
                                                    value={address}
                                                    onChange={handleChange}
                                                    onSelect={handleSelect}
                                                >
                                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                        <div>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-address"
                                                            >
                                                                Address*
                                                            </label>
                                                            <Input
                                                                id="input-address"
                                                                {...getInputProps({
                                                                    placeholder: 'Search an address ...',
                                                                    className: 'location-search-input',
                                                                })}
                                                            />
                                                            <PlaceOptionContainer className="autocomplete-dropdown-container">
                                                                {loading && <PlacesLoading><LoadingOutlined />&nbsp;Loading...</PlacesLoading>}
                                                                {suggestions.map(suggestion => {
                                                                    const className = suggestion.active
                                                                        ? 'suggestion-item--active'
                                                                        : 'suggestion-item';
                                                                    // inline style for demonstration purpose
                                                                    const style = suggestion.active
                                                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                                    return (
                                                                        <PlaceOption
                                                                            key={suggestion.placeId}
                                                                            {...getSuggestionItemProps(suggestion, {
                                                                                className,
                                                                                style,
                                                                            })}
                                                                        >
                                                                            <span>{suggestion.description}</span>
                                                                        </PlaceOption>
                                                                    );
                                                                })}
                                                            </PlaceOptionContainer>
                                                        </div>
                                                    )}
                                                </PlacesAutocomplete>
                                            </Col>
                                            <Col md="6">
                                                {
                                                    locationData.city && <>
                                                        <label className="form-control-label">Details</label>
                                                        <LocationDataItem>City: {locationData.city}</LocationDataItem>
                                                        <LocationDataItem>Province: {locationData.province}</LocationDataItem>
                                                        <LocationDataItem>Country: {locationData.country}</LocationDataItem>
                                                        <LocationDataItem>Latitude: {locationData.lat}</LocationDataItem>
                                                        <LocationDataItem>Longitude: {locationData.lng}</LocationDataItem>
                                                    </>
                                                }
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="locationName"
                                                    >
                                                        Location name *
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="locationName"
                                                        placeholder="Location name"
                                                        value={locationName}
                                                        onChange={e => setLocationName(e.target.value)}
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="location-phone-number"
                                                    >
                                                        Phone number *
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="location-phone-number"
                                                        placeholder="Phone number"
                                                        type="number"
                                                        value={locationPhone}
                                                        onChange={e => setLocationPhone(e.target.value)}
                                                        min={0}
                                                        step={1}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="locationName"
                                                    >
                                                        Province *
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="province"
                                                        placeholder="Province"
                                                        value={locationProvince}
                                                        onChange={e => setLocationProvince(e.target.value)}
                                                        type="select"
                                                    >

                                                        <option value={null}>Pick a province</option>
                                                        {
                                                            provinces.map(p => <option value={p}>{p}</option>)
                                                        }
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Button onClick={addLocation} style={{ display: "flex", alignItems: 'center' }} color="primary"><PlusOutlined />&nbsp;Add Location</Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                    <hr className="my-4" />
                                    {/* Description */}
                                    <h6 className="heading-small text-muted mb-4">Billing Information</h6>
                                    <Button color="primary" onClick={checkBillingInformation} >Check my billing information via Stripe</Button>
                                    <hr className="my-4" />
                                    {/* Description */}
                                    <h6 className="heading-small text-muted mb-4">Payout Information</h6>
                                    {(accountInfo || {}).bank ? (
                                        <>
                                            <h3>Account connected:</h3>
                                            <LocationDataItem>Bank: {accountInfo.bank}</LocationDataItem>
                                            <LocationDataItem>Ended: {accountInfo.last4}</LocationDataItem>
                                            <LocationDataItem>If you want to update your account please contact us at help@grubx.io</LocationDataItem>
                                            {/* <Button color="primary" onClick={() => addAccountInfo('update')} >Update my payout information</Button> */}
                                        </>
                                    ) : <Button color="primary" onClick={addAccountInfo} >Set my payout information via Stripe</Button>}
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Profile;
