/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import { Loader } from "components/Loader";
import { useState } from "react";
import {
	Button,
	Card,
	CardBody,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Col,
} from "reactstrap";
import { message, Result } from 'antd';
import PublicCardHeader from "components/Headers/PublicCardHeader";
import { initResetPassword } from "services/user.service";
import { resetPassword } from "services/user.service";

const ForgotPassword = () => {
	const urlSearchParams = new URLSearchParams(window.location.search);
	const params = Object.fromEntries(urlSearchParams.entries());
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [success, setSuccess] = useState(false);
	const [confirmPassword, setConfirmPassword] = useState('');
	const [loading, setLoading] = useState(null);
	const [linkSent, setLinkSent] = useState(params.token ? true : false);

	const onFinish = async () => {
		try {
			if (!linkSent) {
				await requestResetPasswordEmail(email);
			} else {
				await _resetPassword(password)
			}
		} catch (e) {
			setLoading(null);
			message.error('Something was wrong, please try again later');
		}
	};

	const requestResetPasswordEmail = async email => {
		if (!email) return;
		setLoading('Verifying and sendind link to your email...');
		await initResetPassword(email);
		setLoading(null);
		setLinkSent(true);
		message.success('Link sent! Please verify your email')
	}
	const _resetPassword = async password => {
		if (password !== confirmPassword) {
			return message.error("Passwords don't match")
		}
		if (params.token) {
			setLoading('Updating password...');
			await resetPassword(password, params.token);
			setLoading(null);
			setSuccess(true);
		}
	}

	if (success) return (
		<Col lg="5" md="7">
			<Card className="bg-secondary">
				<CardBody className="">
					<Result
						status="success"
						title="Password updated successfully"
						subTitle="You can go now and login into your account."
						extra={[
							<Button onClick={() => window.location.href = '/login'} color="primary" key="console">
								Go to login
							</Button>
						]}
					/>
				</CardBody>
			</Card>
		</Col>
	)

	return (
		<>
			{loading && <Loader text={loading} />}
			<Col lg="5" md="7">
				<Card className="bg-secondary">
					<CardBody className="px-lg-5 py-lg-5">
						<PublicCardHeader title="Reset your password" description={linkSent ? "Please introduce your new password" : "We will email you a link that allows you to change your password, the link expires in 15 minutes"} />
						<Form role="form">
							{
								linkSent ? (
									<>
										<FormGroup>
											<InputGroup className="input-group-alternative">
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="ni ni-lock-circle-open" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder="Password"
													type="password"
													autoComplete="new-password"
													onChange={e => setPassword(e.target.value)}
													value={password}
												/>
											</InputGroup>
										</FormGroup>

										<FormGroup>
											<InputGroup className="input-group-alternative">
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="ni ni-lock-circle-open" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder="Confirm password"
													type="password"
													autoComplete="new-password"
													onChange={e => setConfirmPassword(e.target.value)}
													value={confirmPassword}
												/>
											</InputGroup>
										</FormGroup>
									</>

								) : (

									<FormGroup className="mb-3">
										<InputGroup className="input-group-alternative">
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
													<i className="ni ni-email-83" />
												</InputGroupText>
											</InputGroupAddon>
											<Input
												placeholder="Email"
												type="email"
												autoComplete="new-email"
												onChange={e => setEmail(e.target.value)}
												value={email}
											/>
										</InputGroup>
									</FormGroup>

								)
							}
							<div className="text-center">
								<Button onClick={onFinish} className="my-4" color="primary" type="button">
									{linkSent ? 'Update password' : 'Send link'}
								</Button>
							</div>
						</Form>
					</CardBody>
				</Card>
			</Col>
		</>
	);
};

export default ForgotPassword;
