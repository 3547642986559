import {
	Card,
	Input,
	Row,
	Container
} from "reactstrap";
import { useState, useEffect, useContext, useCallback } from 'react';
import { AppContext } from '../../AppContext';
import { Loader } from '../../components/Loader';
import Header from 'components/Headers/Header';
import { getAllOrders } from 'services/order.service';
import OrdersTable from './OrdersTable';
import { CustomDrawer, ButtonsContainer } from './styled';
import OrderInformation from './OrderInformation';
import { Empty, DatePicker, Space, message } from 'antd';
const { RangePicker } = DatePicker;

export default function Orders() {
	const [order, setOrder] = useState({});
	const [loading, setLoading] = useState(null);
	const [orders, setOrders] = useState([]);
	const [rangeDates, setRangeDates] = useState([]);
	const [orderStatus, setOrderStatus] = useState('all');
	const { store } = useContext(AppContext);

	const onClose = () => {
		setOrder({});
	};

	const fetchOrders = useCallback(async () => {
		if (store.id) {
			try {
				setLoading('Fetching orders');
				const orders = await getAllOrders(store.id, orderStatus, rangeDates[0], rangeDates[1]);
				setOrders(orders);
				setLoading(null);
			} catch(e) {
				setLoading(null);
				message.error(e.response.data.error.message)
			}
			
		}
	}, [orderStatus, rangeDates, store.id]);

	useEffect(() => {
		fetchOrders();
	}, [store, orderStatus, rangeDates, fetchOrders]);

	const onOrderOpened = order => {
		setOrder(order);
	}

	const onRangePickerChange = date => {
		setRangeDates((date || []).map(d => d.format('YYYY-MM-DD')));
	}

	return (
		<>
			{loading && <Loader text={loading} />}
			<Header />
			{/* Page content */}
			<Container className="mt--7" fluid>
				<Row>
					<div className="col">
						<Card className="shadow border-0">
							<ButtonsContainer>

								<Input type="select" style={{ width: 120 }} value={orderStatus} onChange={e => setOrderStatus(e.target.value)}>
									<option value="all">All orders</option>
									<option value="PENDING">Pending</option>
									<option value="READY_FOR_DRIVER">Ready for driver</option>
									<option value="READY_FOR_PICKUP">Ready for pickup</option>
									<option value="IN_PROGRESS">In progress</option>
									<option value="ON_ROUTE">On route</option>
									<option value="COMPLETED">Completed</option>
									<option value="PAID">Paid</option>
								</Input>
								<Space style={{marginLeft: 20}} size={12}>
									<RangePicker onChange={onRangePickerChange} />
								</Space>
							</ButtonsContainer>
							{orders.length > 0 ? <OrdersTable onOrderOpened={onOrderOpened} orders={orders} /> : <Empty style={{marginBottom: 20}} />}
							<CustomDrawer
								title={`Order #${(order || {}).orderNo}`}
								width={520}
								placement="right"
								onClose={onClose}
								visible={order.id !== undefined}
							>
								<OrderInformation onOrderUpdated={fetchOrders} order={order} />
							</CustomDrawer>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
}