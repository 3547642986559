import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import 'antd/dist/antd.css';
import 'react-chat-elements/dist/main.css';
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { AppContextProvider } from "AppContext";

ReactDOM.render(
  <AppContextProvider>
    <BrowserRouter>
      <Switch>
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        <Route path="/" render={(props) => <AuthLayout {...props} />} />
        <Redirect from="*" to="/admin/dashboard" />
      </Switch>
    </BrowserRouter>
  </AppContextProvider>
  ,
  document.getElementById("root")
);
