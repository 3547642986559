/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import { useHistory } from "react-router-dom";
import {
	Button,
	Card,
	CardBody,
	Container,
	Col,
	Row,
} from "reactstrap";
import { Result } from 'antd';
import { useContext, useEffect, useState } from "react";
import { activateStore } from "services/store.service";
import { AppContext } from "AppContext";
import Header from "components/Headers/Header";
import { Loader } from "components/Loader";

const StripeResult = () => {
	const urlParams = new URLSearchParams(window.location.search);
	const session_id = urlParams.get('session_id');
	const { store } = useContext(AppContext);
	const [paid, setPaid] = useState(false);
	const [loading, setLoading] = useState(session_id ? true : false);
	const history = useHistory();


	useEffect(() => {
		const init = async () => {
			if (store && session_id) {
				const session = await activateStore(store.id, session_id);
				setPaid(session.payment_status === 'paid');
				setLoading(false);
			}
		}
		init();
	}, [store, session_id])

	return (
		<>
			{loading && <Loader text="Checking purchase..." />}
			<Header />
			<Container className="mt--7" fluid>
				<Row>
					<Col>
						<Card className="bg-secondary shadow border-0">
							<CardBody className="px-lg-5 py-lg-5">
								{
									store.id && paid ? (
										<Result
											status="success"
											title={`Purchase Completed Successfully!`}
											subTitle="You can check your billing preferences anytime on the settings section."
											extra={[
												<Button onClick={() => history.push('/admin/products')} color="primary">
													Create your first products!
												</Button>,
											]}
										/>
									) : store.id && !loading ? (
										<Result
											status="error"
											title={`Your purchase was declined`}
											subTitle="Please check your card and try again"
											extra={[
												<Button onClick={() => history.push('/admin/dashboard')} color="primary">
													Go to dashboard
												</Button>,
											]}
										/>
									): (
										<Result
											status="info"
											title={`Checking Purchase Result`}
											subTitle="Please wait while we validate if the payment is correctly processed"
											extra={[
												<Button onClick={() => history.push('/admin/dashboard')} color="primary">
													Go to dashboard
												</Button>,
											]}
										/>
									)
								}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default StripeResult;
