import { request } from "../helpers/request"
const BASE_URL = '/users';

export const getUserInfo = async () => {
    const { data } = await request.get(`${BASE_URL}`);
    return data;
}

export const updateUser = async (data) => {
    return await request.put(`${BASE_URL}`, data);
}

export const loginStore = (body) => {
    return request.post(`${BASE_URL}/login`, body)
}

export const initResetPassword = email => {
    return request.post(`${BASE_URL}/init-reset-password`, { email })
}

export const resetPassword = (newPassword, token) => {
    return request.post(`${BASE_URL}/reset-password`, { newPassword, token })
}

export const getTwilioToken = async (storeId) => {
    const { data } = await request.get(`${BASE_URL}/twilio-token-store/${storeId}`);
    return data;
}