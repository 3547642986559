import { request } from "../helpers/request"
const BASE_URL = '/orders'

export const getOne = async (orderId) => {
    const { data } = await request.get(`${BASE_URL}/${orderId}`);
    return data;
}

export const getDashboardStats = async (storeId) => {
    const { data } = await request.get(`${BASE_URL}/stats/${storeId}`);
    return data;
}

export const getLiveOrders = async (storeId) => {
    const { data } = await request.get(`${BASE_URL}/live/${storeId}`);
    return data;
}

export const getAllOrders = async (storeId, status, startDate, endDate) => {
    const { data } = await request.get(`${BASE_URL}/store/${storeId}`, {
        params: {
            status: status === 'all' ? null: status,
            startDate: startDate || null,
            endDate: endDate || null
        }
    });
    return data;
}

export const updateOrder = (orderId, status, cartItemsIds, refundStatus) => {
    return request.put(`${BASE_URL}/status/${orderId}`, { status, cartItemsIds, refund: refundStatus });
}

export const updateOrderEdt = (orderId, minutes) => {
    return request.put(`${BASE_URL}/delivery-date/${orderId}`, { minutes });
}
