import { BASE_URL, TOKEN_KEY } from "./utils";
const axios = require('axios');

const request = axios.create({
    baseURL: BASE_URL + "/api/v1",
    timeout: 60000
});

request.interceptors.request.use(function (config) {
    config.headers.Authorization =  `Bearer ${window.localStorage.getItem(TOKEN_KEY)}`;
    return config;
});

request.interceptors.response.use(function(response) {
    return response;
}, function(error) {
    // const isPublic = ["/login"].indexOf(window.location.pathname) !== -1;
    // if (!isPublic && (error.response || {}).status === 401) {
    //     window.localStorage.clear();
    //     window.location.href = "/login";
    // }
    return Promise.reject(error);
});

export {
    request,
    BASE_URL
};